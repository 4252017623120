/**
 * Issue:
 *
 * When right-clicking on a keen-slider's child elements and selecting an option from the context menu,
 * the mouseup event doesn't fire properly. As a result, the slider continues to behave as if the mouse button is still
 * pressed (dragging) even though the button is released.
 *
 * This happens specifically in Safari browsers due to how context menu interactions are handled in combination
 * with mouse events. The absence of a mouseup event after right-clicking causes the slider to continue
 * in a dragged state until another action is performed, leading to an inconsistent and broken user experience.
 *
 * Solution:
 * To fix this issue, a custom solution has been implemented to manually trigger a `mouseup` event
 * when a context menu is opened on a slider element.
 * This ensures that the dragging state is reset properly when the user interacts with the context menu.
 *
 * Usage:
 * - Apply the `v-context-menu-fix` directive to keen-slider element where context menu behavior needs to be fixed.
 * - This directive should only activate in Safari browsers to avoid unnecessary overhead.
 */

/* eslint-disable no-param-reassign */
export default {
  bind(el, binding, vnode) {
    const vm = vnode.context;

    if (!vm.$device.isSafari) return;

    el.contextMenuHandler = (event) => {
      if (event.target && el.contains(event.target)) {
        const mouseUpEvent = new MouseEvent('mouseup', {
          bubbles: true,
          cancelable: true,
          view: window,
          button: 2,
          clientX: event.clientX,
          clientY: event.clientY,
        });

        event.target.dispatchEvent(mouseUpEvent);
      }
    };

    el.addEventListener('contextmenu', el.contextMenuHandler);
  },
  unbind(el) {
    if (!el.contextMenuHandler) return;

    el.removeEventListener('contextmenu', el.contextMenuHandler);
    delete el.contextMenuHandler;
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/1sb_logo_v3_white_v1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/cms/icon-sb-link-arrow-red-color-16.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_header_kocHV{--1sb-logo-desktop:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");--arrow-icon:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");align-items:center;background:var(--cp-primary_main);display:flex;justify-content:space-between;margin:0;padding:0;position:relative;width:100%}.page_header__container_IXZFv{display:flex;flex-flow:row wrap;flex-wrap:wrap;margin:0 auto;max-width:1608px;padding:1.6rem 2.4rem;width:100%}.page_header__logo_oI15t a{display:block;vertical-align:middle}.page_header__logo_img_5x8Vk{background:var(--1sb-logo-desktop) 0 0 no-repeat;background-size:16.8rem 5.6rem;display:block;height:5.6rem;margin:0;padding:0;width:16.8rem}.page_header__wrapper_SwQAQ{align-items:center;display:flex;flex:auto;justify-content:space-between;margin-left:4rem;margin-right:auto;padding:0 0 0 4rem;position:relative}.page_header__wrapper_SwQAQ:before{border-left:1px solid var(--cp-white);bottom:-.3rem;content:\"\";display:block;left:0;position:absolute;top:-.3rem}@media only screen and (min-width:768px){.page_header__wrapper_SwQAQ:before{bottom:-.6rem;top:-.6rem}}@media only screen and (min-width:1264px){.page_header__wrapper_SwQAQ:before{bottom:-1.3rem;top:-1.3rem}}.page_header__link_67O5i{color:var(--cp-white)!important;font-size:2.4rem;font-weight:600;line-height:3rem;position:relative}.page_header__link_67O5i:hover{-webkit-text-decoration:underline;text-decoration:underline}.page_header__shopMore_Njl1F{background:var(--arrow-icon) no-repeat right 2px/16px auto;color:var(--cp-white)!important;display:inline-block;font-size:1.2rem;font-weight:600;line-height:2.2rem;margin:0;padding:0 2.4rem 0 0;position:relative;white-space:nowrap}.page_header__shopMore_Njl1F:hover{-webkit-text-decoration:underline;text-decoration:underline}@media only screen and (min-width:768px){.page_header__shopMore_Njl1F{font-size:1.6rem;position:relative;top:1px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_header": "page_header_kocHV",
	"page_header__container": "page_header__container_IXZFv",
	"page_header__logo": "page_header__logo_oI15t",
	"page_header__logo_img": "page_header__logo_img_5x8Vk",
	"page_header__wrapper": "page_header__wrapper_SwQAQ",
	"page_header__link": "page_header__link_67O5i",
	"page_header__shopMore": "page_header__shopMore_Njl1F"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./StaticHeader.vue?vue&type=template&id=c28bdc68&scoped=true"
import script from "./StaticHeader.vue?vue&type=script&lang=js"
export * from "./StaticHeader.vue?vue&type=script&lang=js"
import style0 from "./StaticHeader.vue?vue&type=style&index=0&id=c28bdc68&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c28bdc68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StaticBurgerMenu: require('/var/www/html/js/productlisting/components/google/StaticBurgerMenu.vue').default,BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default})

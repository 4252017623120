export default (context, inject) => {
  // eslint-disable-next-line max-len,vue/max-len
  const re = /(Lighthouse|moto g power|AdsBot-Google|Googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|msnbot|facebookexternalhit|openindexspider|web-archive-net.com.bot|Twitterbot|Facebot|archive.org_bot|Applebot|Pinterest|TelegramBot|WhatsApp|Bing|facebookcatalog|Storebot-Google|GoogleOther)/i;
  const userAgent = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent;
  const isSpider = re.test(userAgent);

  inject('isSpider', isSpider);
};

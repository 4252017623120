// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[class*=default-error-message] p{display:inline}[class*=default-error-message] p:first-of-type{margin-right:4px}body .alertWrapper_IjuJs:before{left:50%;margin-left:-50vw;opacity:1;width:100vw;z-index:-1}body .alertWrapper_IjuJs.alertSuccess_-Zyg3:before{background:var(--cp-tertiary_main-2)}body .alertWrapper_IjuJs.alertWarning_R--dT:before{background:var(--cp-secondary-3)}body .alertWrapper_IjuJs.alertError_wxJxG:before{background:var(--cp-tertiary_variant-1)}body .alertWrapper_IjuJs.alertNotice_CBVE-:before{background:var(--cp-white)}body .alertWrapper_IjuJs.alertWishList_K8nC1{align-items:center;background:var(--cp-white)!important;border-radius:.8rem;bottom:2.4rem;box-shadow:0 0 1.2rem 0 var(--cp-color-98)!important;display:none;font-size:1.6rem;font-weight:600;height:5.6rem;left:50%;line-height:2.2rem;padding:1.6rem 3.2rem;position:fixed;transform:translateX(-50%);white-space:nowrap;width:-moz-fit-content;width:fit-content;z-index:100}body .alertWrapper_IjuJs.alertWishList_K8nC1:before{content:none;display:none}body .alertWrapper_IjuJs.alertWishList_K8nC1:last-child{display:flex}body .alertWrapper_IjuJs.alertWishList_K8nC1 .v-alert__wrapper>.vue-icon{margin-right:1.2rem}body .alertWrapper_IjuJs.alertWishList_K8nC1 .v-alert__dismissible{display:none}.v-alert{font-size:1.4rem;line-height:2rem;margin-left:auto;margin-right:auto;max-width:1560px;padding:16px}@media only screen and (min-width:768px){.v-alert{font-size:1.6rem;line-height:2.2rem}}.v-alert .v-alert__wrapper{color:var(--cp-black)}.v-alert .vue-icon{flex-shrink:0;margin-right:16px}@media only screen and (min-width:768px){.v-alert .vue-icon{margin-right:24px}}.outofstock_alert_itqB4{background:var(--cp-tertiary_variant-1)!important;margin-bottom:0!important}.outofstock_alert_itqB4:before{display:none!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertWrapper": "alertWrapper_IjuJs",
	"alertSuccess": "alertSuccess_-Zyg3",
	"alertWarning": "alertWarning_R--dT",
	"alertError": "alertError_wxJxG",
	"alertNotice": "alertNotice_CBVE-",
	"alertWishList": "alertWishList_K8nC1",
	"outofstock_alert": "outofstock_alert_itqB4"
};
module.exports = ___CSS_LOADER_EXPORT___;

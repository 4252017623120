// eslint-disable-next-line func-names
export default function (ctx) {
  const config = ctx?.$config?.errorSender;
  if (typeof window === 'undefined' || !config) return;

  class ErrorSender {
    constructor(locationData) {
      this.endpoint = config.endpoint;
      this.messagesLimit = config.messagesLimit;
      this.origin = locationData?.origin;
    }

    async send(data) {
      if (!this.origin || this.messagesLimit <= 0) return undefined;

      try {
        this.messagesLimit -= 1;
        return await fetch(`${this.origin}${this.endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data }),
        });
      } catch (e) {
        return undefined;
      }
    }
  }

  function collectStacktrace(error) {
    return (error && error.stack) ? error.stack : { unsupported: 'No stack trace available' };
  }

  function gatherLocation() {
    return {
      origin: window.location.origin,
      pathname: window.location.pathname,
      search: window.location.search || undefined,
    };
  }

  const errorSender = new ErrorSender({ origin: window.location.origin });

  function sendError(error, additionalData = {}) {
    try {
      const errMessage = error.message || 'Error';
      const url = window.location.href;

      errorSender.send({
        stacktrace: collectStacktrace(error),
        location: gatherLocation(),
        message: errMessage,
        url,
        line: additionalData.lineno || additionalData.line,
      });
    } catch (e) {
      // Silent failure - don't let error tracking break the app
    }
  }

  // Error event listener
  window.addEventListener('error', (event) => {
    sendError(event.error || new Error(event.message || 'Unknown error'), {
      message: event.message,
      filename: event.filename,
      line: event.lineno,
      colno: event.colno,
      handler: 'error event',
    });
  });

  // Unhandled Promise Rejection handler
  window.addEventListener('unhandledrejection', (event) => {
    sendError(event.reason || new Error('Unhandled Promise Rejection'), {
      message: event.reason?.message || 'Unhandled Promise Rejection',
      handler: 'unhandledrejection',
    });
  });

  // Configure Vue error handler
  setTimeout(() => {
    try {
      const Vue = window.$nuxt?.$root?.constructor;
      if (Vue?.config) {
        const originalErrorHandler = Vue.config.errorHandler;
        Vue.config.errorHandler = (error, vm, info) => {
          try {
            const errMessage = error?.message || 'Unknown Vue error';
            const url = window.location.href;

            errorSender.send({
              stacktrace: collectStacktrace(error),
              location: gatherLocation(),
              message: errMessage,
              url,
              line: null,
            });
          } catch (e) {
            // Silent failure - don't let error tracking break the app
          }

          if (typeof originalErrorHandler === 'function') {
            originalErrorHandler(error, vm, info);
          }
        };
      }
    } catch (e) {
      // Fail silently if Vue is not available
    }
  }, 10);
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/dropdown.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/dropdown-black.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".base-select[data-v-19bc003d]{--primary-color:var(--cp-secondary-1)}.base-select.is_boutique[data-v-19bc003d]{--primary-color:var(--cp-tertiary_special-main)}.base-select[data-v-19bc003d] .v-list-item{font-size:1.6rem;min-height:32px;min-width:73px}.base-select[data-v-19bc003d] .v-list{padding:0}.base-select[data-v-19bc003d] .v-list:hover .v-list-item--active{background:var(--cp-white);color:var(--cp-black);z-index:0}.base-select[data-v-19bc003d] .v-list:hover .v-list-item--active:after,.base-select[data-v-19bc003d] .v-list:hover .v-list-item--active:before{opacity:0}.base-select[data-v-19bc003d] .v-list:not(:hover) .v-list-item--active{background:var(--primary-color);color:var(--cp-white);z-index:0}.base-select[data-v-19bc003d] .v-list .v-list-item:hover{background:var(--primary-color);color:var(--cp-white)}.base-select[data-v-19bc003d] .v-menu__content{border:.5px solid var(--cp-gray-3);border-radius:8px;box-shadow:0 0 24px 0 var(--cp-color-97)}.base-select[data-v-19bc003d] .admin-select:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.base-select[data-v-19bc003d].is_boutique .admin-select:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.base-select[data-v-19bc003d] .each-product-count{width:12px}.base-select[data-v-19bc003d] .each-product-price{font-size:1.4rem;font-weight:400;line-height:2rem;margin-left:16px;white-space:nowrap}@media only screen and (max-width:1903.98px){.base-select[data-v-19bc003d] .each-product-wrapper{align-items:center;display:flex;min-height:42px;min-width:208px;position:relative}.base-select[data-v-19bc003d] .each-product-wrapper:after{border-bottom:1px solid var(--cp-gray-4);bottom:0;content:\"\";margin:0 -16px;position:absolute;width:240px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loadingPopup_Wml3h{left:0;top:0;z-index:999}.loadingPopup_Wml3h,.loadingPopup_Wml3h:before{height:100vh;position:fixed;width:100vw}.loadingPopup_Wml3h:before{background:var(--cp-black);content:\"\";opacity:.6}.loadingBlock_M-qrZ{left:50vw;position:fixed;top:50vh;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingPopup": "loadingPopup_Wml3h",
	"loadingBlock": "loadingBlock_M-qrZ"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=4f4787a2"
import script from "./Wrapper.vue?vue&type=script&lang=js"
export * from "./Wrapper.vue?vue&type=script&lang=js"
import style0 from "./Wrapper.vue?vue&type=style&index=0&id=4f4787a2&prod&lang=scss&module=true"
import style1 from "./Wrapper.vue?vue&type=style&index=1&id=4f4787a2&prod&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMenuMobile: require('/var/www/html/js/productlisting/components/templates/header/menu/Mobile.vue').default,HeaderMenuDesktop: require('/var/www/html/js/productlisting/components/templates/header/menu/Desktop.vue').default})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialog_77Xzb{background-color:var(--cp-white);position:relative}.dialog_77Xzb.hasExternalScroll{background-color:transparent;background-color:initial;border-radius:0;box-shadow:none;filter:none;height:100%;margin:0;max-height:none;padding:0}.dialog_77Xzb.hasExternalScroll .innerDialog{align-items:center;display:flex;justify-content:center;max-width:100%;min-height:100%;min-width:0;padding:5vh 24px;position:relative}.dialog_77Xzb.hasExternalScroll .innerOverlay{position:absolute;z-index:1}.dialog_77Xzb.hasExternalScroll .dialogContent{border-radius:4px;box-shadow:none;filter:drop-shadow(0 0 8px var(--cp-color-97));max-width:100%;min-width:0;position:relative;z-index:1}.dialog_77Xzb.hasExternalScroll.v-dialog--fullscreen{background-color:var(--cp-white)}.dialog_77Xzb.hasExternalScroll.v-dialog--fullscreen .innerDialog{align-items:stretch;padding:0}.dialog_77Xzb.hasExternalScroll.v-dialog--fullscreen .dialogContent{width:100%}.dialog_77Xzb.hasExternalScroll.v-dialog--fullscreen .content_AKFlm{min-height:100%}.content_AKFlm{color:var(--cp-black);padding:24px 16px 48px}@media only screen and (min-width:768px){.content_AKFlm{padding:48px 32px}}.close_yCEeG{display:flex;justify-content:flex-end;margin:-30px -6px 0 0;position:relative;z-index:10}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "dialog_77Xzb",
	"content": "content_AKFlm",
	"close": "close_yCEeG"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".letterBlock_\\+djlD{padding-bottom:1.6rem}.letter_Niox1{color:var(--cp-black);font-size:2.4rem;font-weight:600;line-height:3rem;padding:1.6rem 0;text-transform:uppercase}@media only screen and (min-width:1264px){.letter_Niox1{background:var(--cp-white);position:sticky;top:0;z-index:1}}.link_TyvoA{color:var(--cp-black)!important;display:block;font-size:1.6rem;font-weight:400;line-height:2.2rem;padding:1.66rem 0;position:relative}.link_TyvoA:hover{-webkit-text-decoration:underline;text-decoration:underline}.link_TyvoA:after{border-bottom:.1rem solid var(--cp-gray-4);bottom:0;content:\"\";display:block;left:0;position:absolute;right:0}.letterBlock_\\+djlD.menuLetter_yFtb2 a:focus-visible{outline:var(--cp-accessibility-outline);outline-offset:-1px;-webkit-text-decoration:underline;text-decoration:underline}.pageLetter_fzANX .letter_Niox1{background:var(--cp-transparent)}@media only screen and (min-width:1264px){.pageLetter_fzANX .letter_Niox1{background:var(--cp-transparent)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"letterBlock": "letterBlock_+djlD",
	"letter": "letter_Niox1",
	"link": "link_TyvoA",
	"menuLetter": "menuLetter_yFtb2",
	"pageLetter": "pageLetter_fzANX"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./AlertsList.vue?vue&type=template&id=65bcb787&scoped=true"
import script from "./AlertsList.vue?vue&type=script&lang=js"
export * from "./AlertsList.vue?vue&type=script&lang=js"
import style0 from "./AlertsList.vue?vue&type=style&index=0&id=65bcb787&prod&lang=scss&module=true"
import style1 from "./AlertsList.vue?vue&type=style&index=1&id=65bcb787&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "65bcb787",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseAlert: require('/var/www/html/js/productlisting/components/common/BaseAlert.vue').default})

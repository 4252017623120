
import respUtility from '@/mixins/respUtility';

export default {
  name: 'CartShipping',
  mixins: [respUtility],
  props: {
    delivery: {
      type: Object,
      default() {
        return {};
      },
    },
    isGuardian: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFree() {
      return this.delivery?.method === 'ups_fedex' || this.delivery?.isFreeShipping;
    },
    isDescription() {
      if (this.isGuardian) {
        return false;
      }
      return this.delivery?.isDescriptionEnable && !(this.$device.isMobileOrTablet || this.isTouchDevice);
    },
    callUs() {
      return this.$store.state.cart.phone;
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_SPqxI{font-size:18px;font-weight:600;line-height:26px;text-align:center;width:100%}@media only screen and (min-width:768px){.title_SPqxI{font-size:16px;font-weight:600;line-height:22px;text-align:left;width:auto}}.icon_GeFf-{margin-left:-6px;margin-right:4px;fill:var(--cp-white);display:none}@media only screen and (min-width:768px){.icon_GeFf-{display:block}}.contacts_ZTRZX{align-items:flex-start;display:flex;flex-wrap:wrap;gap:16px;justify-content:space-between;padding:17px 0;position:relative}@media only screen and (min-width:768px){.contacts_ZTRZX{flex-direction:column;justify-content:flex-start;padding:0;width:196px}}.buttonContainer_mxtsG{width:calc(50% - 12px)}@media only screen and (min-width:768px){.buttonContainer_mxtsG{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_SPqxI",
	"icon": "icon_GeFf-",
	"contacts": "contacts_ZTRZX",
	"buttonContainer": "buttonContainer_mxtsG"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_9tVk1{align-items:center;display:flex;height:2.4rem;grid-gap:.8rem;color:var(--cp-white);gap:.8rem;padding:0 .1rem 0 0}.link_9tVk1:hover{-webkit-text-decoration:underline;text-decoration:underline}.link_9tVk1.link-bold_IEf6w{font-weight:600}.link_9tVk1.mr-auto_uRgr2{margin-right:auto;grid-gap:1.6rem;gap:1.6rem}.icon_HptYS{flex:0 0 auto;height:2.4rem;width:2.4rem}.title_lZyL5{font-size:1.6rem;line-height:2.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_9tVk1",
	"link-bold": "link-bold_IEf6w",
	"mr-auto": "mr-auto_uRgr2",
	"icon": "icon_HptYS",
	"title": "title_lZyL5"
};
module.exports = ___CSS_LOADER_EXPORT___;

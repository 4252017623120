export default {
  "transparent": "rgba(0,0,0,0)",
  "white": "#FFFFFF",
  "black": "#000000",
  "gray0": "#3F3F3F",
  "gray1": "#757575",
  "gray2": "#848484",
  "gray3": "#ABABAB",
  "gray4": "#B5B5B5",
  "gray5": "#D8D8D8",
  "gray6": "#E8E8E8",
  "gray7": "#EFEFEF",
  "gray8": "#F2F2F2",
  "gray9": "#F8F8F8",
  "primary_main": "#0E259A",
  "primary_brand_new": "#1E4FC0",
  "primary_support": "#F25D0D",
  "primary_main_darker": "#0B1E7B",
  "primary_support_darker0": "#D04900",
  "primary_support_darker1": "#DA540C",
  "secondary_main": "#435FEE",
  "secondary1": "#6A82EA",
  "secondary2": "#A3B2CE",
  "secondary3": "#D7DBED",
  "secondary4": "#F0F2FF",
  "secondary_support": "#FD822E",
  "tertiary_main": "#347436",
  "tertiary_main1": "#A8C658",
  "tertiary_main2": "#D5EAA3",
  "tertiary_main3": "#E4EECA",
  "tertiary_support": "#D21714",
  "tertiary_support2": "#EF5350",
  "tertiary_variant1": "#FFE8E8",
  "tertiary_variant2": "#FEEADB",
  "tertiary_variant3": "#D8D4C5",
  "tertiary_special0": "#795805",
  "tertiary_specialMain": "#8A6300",
  "tertiary_special1": "#B8870A",
  "tertiary_special2": "#CA950B",
  "tertiary_special3": "#D9B046",
  "color1": "#FDFDFD",
  "color2": "#F7F7F7",
  "color3": "#F6F6F6",
  "color4": "#F3F3F3",
  "color5": "#F1F1F1",
  "color6": "#F0F0F0",
  "color7": "#EBEBEB",
  "color8": "#EAEAEA",
  "color9": "#E7E7E7",
  "color10": "#E5E5E5",
  "color11": "#E3E3E3",
  "color12": "#E1E1E1",
  "color13": "#E0E0E0",
  "color14": "#DFDFDF",
  "color15": "#DEDEDE",
  "color16": "#DDDDDD",
  "color17": "#D9D9D9",
  "color18": "#D6D6D6",
  "color19": "#D2D2D2",
  "color20": "#D1D1D1",
  "color21": "#CCCCCC",
  "color22": "#C5C5C5",
  "color23": "#C4C4C4",
  "color24": "#C0C0C0",
  "color25": "#BABABA",
  "color26": "#B9B9B9",
  "color27": "#B6B6B6",
  "color28": "#A0A0A0",
  "color29": "#999999",
  "color30": "#767676",
  "color31": "#737373",
  "color32": "#6B6B6B",
  "color33": "#666666",
  "color34": "#464646",
  "color35": "#434343",
  "color36": "#3D3D3D",
  "color37": "#333333",
  "color38": "#242424",
  "color39": "#9D1411",
  "color40": "#5C1E1C",
  "color41": "#8D8988",
  "color42": "#C73E14",
  "color43": "#B84000",
  "color44": "#DA540B",
  "color45": "#FFC439",
  "color46": "#705000",
  "color47": "#8A6301",
  "color48": "#604500",
  "color49": "#F6F0E0",
  "color50": "#EFE5C9",
  "color51": "#FFF200",
  "color53": "#7CB342",
  "color54": "#E9F3DF",
  "color55": "#D7E8C6",
  "color56": "#62923C",
  "color57": "#7CB053",
  "color58": "#35800F",
  "color59": "#CEE4C8",
  "color61": "#243B25",
  "color62": "#5D6060",
  "color63": "#BECBD2",
  "color64": "#02355D",
  "color65": "#00569B",
  "color66": "#00559B",
  "color67": "#195D9D",
  "color68": "#AEB0B2",
  "color69": "#021F3C",
  "color70": "#001733",
  "color71": "#1967D2",
  "color72": "#2068D0",
  "color73": "#393B3F",
  "color74": "#243AA7",
  "color75": "#1D36B2",
  "color76": "#1B3AD9",
  "color77": "#4A4AF4",
  "color78": "#1515D3",
  "color79": "#1D1D1E",
  "color80": "#ED5370",
  "color81": "#D01833",
  "color82": "#630741",
  "color85": "rgba(227,227,227,.75)",
  "color86": "rgba(225,225,225,.75)",
  "color87": "rgba(255,255,255,.3)",
  "color88": "rgba(255,255,255,.5)",
  "color89": "rgba(255,255,255,.9)",
  "color90": "rgba(255,255,255,.354456)",
  "color91": "rgba(248,248,248,.5)",
  "color92": "rgba(232,232,232,.5)",
  "color93": "rgba(229,229,229,.5)",
  "color94": "rgba(228,228,228,.208)",
  "color95": "rgba(181,181,181,.5)",
  "color96": "rgba(33,30,34,.2)",
  "color97": "rgba(0,0,0,.25)",
  "color98": "rgba(0,0,0,.35)",
  "color99": "rgba(0,0,0,.5)",
  "color100": "rgba(0,0,0,.12)",
  "color101": "rgba(0,0,0,.2)",
  "color102": "rgba(0,0,0,.14)",
  "color103": "rgba(0,0,0,.6)",
  "color104": "rgba(0,0,0,.38)",
  "color105": "rgba(0,0,0,.4)",
  "color106": "rgba(0,0,0,.10)",
  "color107": "rgba(0,0,0,.3)",
  "color108": "rgba(0,0,0,.87)",
  "color109": "rgba(0,0,0,.16)",
  "color110": "rgba(180,235,170,.188)",
  "color111": "rgba(0,174,255,.04)",
  "color112": "rgba(0,174,255,.03)",
  "color113": "rgba(14,37,154,.1)",
  "color114": "rgba(14,37,154,.06)",
  "gray11": "#444444",
  "gray12": "#1F1F1F",
  "red0": "#ff0000",
  "primaryMainLight": "#005ccc",
  "redCompare1": "#af413c",
  "redCompare2": "#90231d",
  "redCompare3": "#b52f28",
  "redCompare4": "#d21714",
  "tertiaryMain5": "#46A049",
  "greenCompare1": "#59734f",
  "greenCompare2": "#465d3e",
  "greenCompare3": "#4d6844",
  "greenCompare4": "#46a049",
  "grayCompare1": "#393c42",
  "grayCompare2": "#262628",
  "grayCompare3": "#151515",
  "grayCompare4": "#2b2c2e",
  "grayCompare5": "#202123",
  "grayCompare6": "#1d1d1e",
  "iconColor": "#000000",
  "boutiqueBg": "rgba(138,99,0,.1)",
  "imageMinWidth": "0",
  "imageMinHeight": "0",
  "accessibilityOutline": "2px solid var(--cp-secondary-1)",
  "accessibilityColor": "var(--cp-secondary-1)"
}
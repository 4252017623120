// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dummyProducts_sDQUB{grid-column:span 2/auto;margin:0;padding-left:7.6rem}@media only screen and (min-width:768px){.dummyProducts_sDQUB{padding-left:0}}.dummyProducts_sDQUB ul{margin:0;padding:0}.dummyProducts_sDQUB ul li{margin:0 0 .4rem;padding:0}.dummyProducts_sDQUB ul li:last-child{margin:0}.dummyProducts_sDQUB ul li span{color:var(--cp-tertiary_main);display:grid;font-size:1.4rem;font-weight:600;grid-template-columns:auto 1fr;line-height:2rem;grid-gap:.8rem}@media only screen and (min-width:768px){.dummyProducts_sDQUB ul li span{grid-gap:1.6rem}}.dummyProducts_sDQUB ul li span span{color:var(--cp-black);display:block;font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:capitalize}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dummyProducts": "dummyProducts_sDQUB"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".socialIcon_AYtOx:not(:last-child){margin-right:22px}.socialIcon_AYtOx svg{margin:auto 0}.socialIcon_AYtOx:hover svg{--icon-color:var(--cp-black)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialIcon": "socialIcon_AYtOx"
};
module.exports = ___CSS_LOADER_EXPORT___;

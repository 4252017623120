// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerScrollTopBtn_knZT8{align-items:center;border:1px solid var(--cp-gray-12);border-radius:50%;bottom:102px;box-shadow:0 3px 5px -1px var(--cp-color-101),0 6px 10px 0 var(--cp-color-102),0 1px 18px 0 var(--cp-color-100);display:flex;height:54px;justify-content:center;position:fixed;right:10px;text-align:center;transition:opacity .6s ease-in-out;width:54px;z-index:11}.footerScrollTopBtn_knZT8.footerScrollTopBtn_knZT8{background-color:var(--cp-white)}.footerScrollTopBtn_knZT8.footerScrollTopBtn_knZT8:hover{background-color:var(--cp-gray-7)}@media only screen and (min-width:768px){.footerScrollTopBtn_knZT8{right:22px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerScrollTopBtn": "footerScrollTopBtn_knZT8"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_qylCn{align-items:center;display:flex;font-size:1.6rem;font-weight:600;line-height:2.2rem}.button_qylCn:hover{-webkit-text-decoration:underline;text-decoration:underline}.button_qylCn:focus-visible{outline:var(--cp-accessibility-outline)}.button_qylCn.is_active_-WqMR svg{transform:rotate(180deg)}.brandsContent_Qslbg{display:flex;position:relative;grid-gap:1px;background:var(--cp-gray-4);color:var(--cp-black);height:100%;width:836px}.brandsContent_Qslbg:before{border-bottom:8px solid var(--cp-transparent);border:8px solid var(--cp-transparent);border-bottom-color:var(--cp-white);content:\"\";left:400px;position:absolute;top:-16px;z-index:20}@media only screen and (min-width:1264px){.brandsContent_Qslbg:before{left:470px}}@media screen and (min-width:1600px){.brandsContent_Qslbg:before{left:504px}}.brandSubmenu_fEZuF{border:none!important;border-radius:0!important;bottom:0;left:0!important;overflow:hidden;width:100%}.luxuryBrands_OOX47{background-color:var(--cp-white);padding:2.4rem .8rem 0 3.2rem}.headerBrandsWrap_n2Jze{height:calc(100% - 16px);margin-top:16px;position:relative}.headerBrandsOverlay_Wh1Yh{bottom:0;left:0;position:absolute;right:0;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_qylCn",
	"is_active": "is_active_-WqMR",
	"brandsContent": "brandsContent_Qslbg",
	"brandSubmenu": "brandSubmenu_fEZuF",
	"luxuryBrands": "luxuryBrands_OOX47",
	"headerBrandsWrap": "headerBrandsWrap_n2Jze",
	"headerBrandsOverlay": "headerBrandsOverlay_Wh1Yh"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".empty_eJ5gO{text-align:center}.empty_eJ5gO .link_owhy2{color:var(--cp-primary_main)}.empty_eJ5gO .link_owhy2:hover{-webkit-text-decoration:underline;text-decoration:underline}.title_fXcTs{font-size:18px;font-weight:600;line-height:26px;margin-bottom:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "empty_eJ5gO",
	"link": "link_owhy2",
	"title": "title_fXcTs"
};
module.exports = ___CSS_LOADER_EXPORT___;

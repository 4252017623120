// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_kjve5{align-self:flex-end;cursor:pointer;height:2.4rem;width:2.4rem}.mobileMenuWrapper_PATxd{--_header-height:var(--hheader-main-part);background:linear-gradient(180deg,var(--cp-gray-8),var(--cp-gray-9));border-radius:0!important;height:calc(100% - var(--_header-height));padding:0 env(safe-area-inset-right) 0 env(safe-area-inset-left);top:var(--_header-height)}.mobileMenuWrapper_PATxd.isDisabled_wU9Jo{overflow:hidden}.mobileMenuContent_ElDZa{background:var(--cp-white);scroll-behavior:smooth}.mobileMenuOverflow_hO13C{height:100vh;margin-bottom:0;overflow:hidden}.mobileTitle_X98tC{border-bottom:1px solid var(--cp-gray-4);display:block;font-size:1.8rem;font-weight:600;line-height:2.6rem;padding:0 0 1.6rem}.mobileMenuBottom_zDkQ9{background-color:var(--cp-gray-9);padding:2.4rem 1.6rem 9.6rem}.mobileMenuItem_8FTL8{border-bottom:.1rem solid var(--cp-gray-4);display:flex;flex-direction:column;padding-bottom:2.4rem;grid-row-gap:.4rem}.mobileMenuItemLink_Fz9nn{align-items:center;display:flex;padding:1.2rem;grid-gap:1.2rem;color:var(--cp-black)!important;font-size:1.8rem;font-weight:600;line-height:2.6rem;width:100%}.mobileMenuItemLink_Fz9nn:hover{-webkit-text-decoration:underline;text-decoration:underline}.mobileMenuItemTitle_v2I8l{display:inline-block;font-size:1.8rem;line-height:2.6rem}.buttonWrap_BTDxY{padding-top:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "btn_kjve5",
	"mobileMenuWrapper": "mobileMenuWrapper_PATxd",
	"isDisabled": "isDisabled_wU9Jo",
	"mobileMenuContent": "mobileMenuContent_ElDZa",
	"mobileMenuOverflow": "mobileMenuOverflow_hO13C",
	"mobileTitle": "mobileTitle_X98tC",
	"mobileMenuBottom": "mobileMenuBottom_zDkQ9",
	"mobileMenuItem": "mobileMenuItem_8FTL8",
	"mobileMenuItemLink": "mobileMenuItemLink_Fz9nn",
	"mobileMenuItemTitle": "mobileMenuItemTitle_v2I8l",
	"buttonWrap": "buttonWrap_BTDxY"
};
module.exports = ___CSS_LOADER_EXPORT___;

export default () => {
  let isVisible = false;
  const observer = new MutationObserver(() => {
    const klaviyoInput = document.querySelector('.klaviyo-form input');
    if (klaviyoInput) {
      isVisible = true;
      window?.$nuxt?.$emit('klaviyo:changed', isVisible);
    } else if (isVisible) {
      isVisible = false;
      window?.$nuxt?.$emit('klaviyo:changed', isVisible);
      observer.disconnect();
    }
  });

  const klaviyoOnsite = JSON.parse(localStorage.getItem('klaviyoOnsite'));
  const disabledForms = klaviyoOnsite ? Object.keys(klaviyoOnsite?.viewedForms?.modal?.disabledForms) : [];

  if (!klaviyoOnsite || !disabledForms.length) {
    observer.observe(document.body, { childList: true, subtree: true });
  }
};

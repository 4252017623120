// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_Kol2\\+{background:var(--cp-primary_main);color:var(--cp-white)}.header_Kol2\\+.is_boutique_AWNJK{background-color:var(--cp-black)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_Kol2+",
	"is_boutique": "is_boutique_AWNJK"
};
module.exports = ___CSS_LOADER_EXPORT___;

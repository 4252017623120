import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=4c93670a"
import script from "./TheHeader.vue?vue&type=script&lang=js"
export * from "./TheHeader.vue?vue&type=script&lang=js"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=4c93670a&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMain: require('/var/www/html/js/productlisting/components/templates/header/Main.vue').default,HeaderAdditional: require('/var/www/html/js/productlisting/components/templates/header/Additional.vue').default,HeaderPromotion: require('/var/www/html/js/productlisting/components/templates/header/Promotion.vue').default})

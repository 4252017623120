// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".whiteGlove_DGNcJ{align-items:center;background:var(--cp-gray-8);border-radius:4px;display:flex;gap:8px;padding:.8rem 1.6rem;width:100%}.whiteGlove_DGNcJ .vue-icon{flex-shrink:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteGlove": "whiteGlove_DGNcJ"
};
module.exports = ___CSS_LOADER_EXPORT___;

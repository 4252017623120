// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapperOnsitePopup_E8rBF{width:100%}.wrapperOnsitePopup_E8rBF [class*=close]{margin:initial;position:absolute;right:10px;top:10px}@media only screen and (min-width:768px){.wrapperOnsitePopup_E8rBF [class*=close]{right:18px;top:18px}}.wrapperOnsitePopup_E8rBF [class*=close] path{fill:var(--cp-gray-2)}@media only screen and (min-width:768px){:root:root .wrapperOnsitePopup_E8rBF .innerDialog{padding-bottom:60px;padding-top:60px}}@media only screen and (min-width:768px){:root:root .wrapperOnsitePopup_E8rBF .dialogContent{max-width:824px;width:100%}}.wrapperOnsitePopup_E8rBF .v-card{padding:16px;text-align:left}:root .wrapperOnsitePopup_E8rBF .v-card{border-radius:8px}@media only screen and (min-width:768px){.wrapperOnsitePopup_E8rBF .v-card{padding:40px 24px 32px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperOnsitePopup": "wrapperOnsitePopup_E8rBF"
};
module.exports = ___CSS_LOADER_EXPORT___;

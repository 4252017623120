// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-application.hasOverlay_PSMNK .v-main__wrap>:not(.overlay_wvRZO){pointer-events:none}.v-application .v-main__wrap .overlay_wvRZO{pointer-events:none}.v-application.noScroll_GRcr9{max-height:100vh;overflow:hidden}.header_\\+UL1I{background:var(--cp-primary_main);padding:17px 16px}@media only screen and (min-width:1264px){.header_\\+UL1I{padding:21px 60px}}.footer_sXvHh{align-items:center;background:var(--cp-color-79);color:var(--cp-white);display:flex;flex-wrap:wrap;gap:8px;justify-content:center;padding:30px;text-align:center}.links_7IFfv{display:flex;line-height:24px}.links_7IFfv .link_wKBiw{color:var(--cp-white)}.links_7IFfv .link_wKBiw:hover{-webkit-text-decoration:underline;text-decoration:underline}.link_wKBiw:not(:last-child){border-right:1px solid var(--cp-gray-4);margin-right:8px;padding-right:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hasOverlay": "hasOverlay_PSMNK",
	"overlay": "overlay_wvRZO",
	"noScroll": "noScroll_GRcr9",
	"header": "header_+UL1I",
	"footer": "footer_sXvHh",
	"links": "links_7IFfv",
	"link": "link_wKBiw"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./LinkWithArrow.vue?vue&type=template&id=e8e6042e"
import script from "./LinkWithArrow.vue?vue&type=script&lang=js"
export * from "./LinkWithArrow.vue?vue&type=script&lang=js"
import style0 from "./LinkWithArrow.vue?vue&type=style&index=0&id=e8e6042e&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default})

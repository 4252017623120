export default (context, inject) => {
  if (!process.server) {
    const localStorageDevice = localStorage.getItem('1sb-device');
    if (localStorageDevice) {
      inject('customDevice', localStorageDevice);
      return;
    }
  }
  let userAgent = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent;
  let device = '';

  if (!userAgent) {
    userAgent = 'Default';
  }

  if (userAgent.includes('1sb-desktop')) {
    device = 'desktop';
  } else if (userAgent.includes('1sb-tablet')) {
    device = 'tablet';
  } else if (userAgent.includes('1sb-mobile')) {
    device = 'mobile';
  }
  if (!device && !process.server) {
    device = window?.matchMedia('(pointer: coarse)')?.matches ? 'mobile' : 'desktop';
  }

  inject('customDevice', device);
};

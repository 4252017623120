// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const FRAGMENT_MENU_DATA = gql`
    fragment fragmentMenuData on NavigationCategory {
        dropdown_title
        icon
        id
        level
        name
        slug
        url
    }
`;

export const FRAGMENT_BRAND_MENU_DATA = gql`
    fragment fragmentBrandMenuData on NavigationBrand {
        icon
        name
        url
        slug
    }
`;

export const FRAGMENT_META_DATA = gql`
    fragment fragmentMetaData on PageMetadata {
        addons {
            breadcrumbs {
                name
                slug
                url
                originalName
            }
            head {
                title
                description
                keywords
                robots
                content_type
                isBoutique
            }
            metaOg {
                title
                url
                type
                description
                image
                image_alt
            }
            ... on CPMeta {
                filters: popularFilters {
                    url
                    label
                    internalRoute
                }
                categories: relatedCategories {
                    url
                    label
                    internalRoute
                }
                seoData: seoBlock {
                    content
                    placement
                }
            }
            ... on BPMeta {
                description
            }
        }
    }
`;

export const FRAGMENT_CART_ITEM_DATA = gql`
    fragment fragmentCartItemData on MinicartItem {
        product {
            productUrl
            price
            pplanSkip
            useInCriteoFeed
            slug
            parentCategory
            category
            optionName
            brand {
                id
                name
            }
            id
            img {
                classes
                style
                thumbnail
            }
            preview {
                classes
                style
                thumbnail
            }
            name
            originalPrice
            clearanceQty
            webId
            freeProtectionIds
            outOfStock
        }
        itemId
        price
        protectionPlan {
            id
            name
            price
            qty
        }
        qty
        regularPrice
        showMsrp
        discount
        coupon
    }
`;

export const FRAGMENT_COUPON = gql`
    fragment fragmentCouponData on CouponDataType {
        code
        timeRemains
        timerText
        secondText
        detailHoverLabel
        detailHoverText
    }
`;

export const FRAGMENT_DUMMYPRODUCTS = gql`
    fragment fragmentDummyProducts on DummyProduct {
        id
        name
        price
        qty
    }
`;

export const FRAGMENT_CART_DATA = gql`
    ${FRAGMENT_CART_ITEM_DATA}
    ${FRAGMENT_COUPON}
    fragment fragmentCartData on MinicartType {
        cartNumber
        summaryCount
        items {
            ...fragmentCartItemData
            ... on BundleCartItem {
              childItems {
                ...fragmentCartItemData
              }
              childItemsCount
              pplanInclude
            }
        }
        subtotal
        couponData {
            ...fragmentCouponData
        }
    }
`;
export const FRAGMENT_CART_PAGE_DATA = gql`
    ${FRAGMENT_CART_ITEM_DATA}
    ${FRAGMENT_COUPON}
    ${FRAGMENT_DUMMYPRODUCTS}
    fragment fragmentCartPageData on CartType {
        cartNumber
        discount
        items {
            ...fragmentCartItemData
            coupon
            discount
            protectionPlan {
                webId
            }
            product {
                delivery {
                    type
                    method
                    date
                    dateHtml
                    isFreeShipping
                    isDescriptionEnable
                    isWhiteGloveEnable
                    freeWg
                }
                cartPreview {
                    classes
                    style
                    thumbnail
                    sources {
                        media
                        srcset
                    }
                }
            }
            ... on BundleCartItem {
              childItems {
                ...fragmentCartItemData
                product {
                    setOf
                    parentCategory
                    category
                }
                protectionPlan {
                    webId
                }
                dummyProducts{
                    ...fragmentDummyProducts
                }
              }
              childItemsCount
              pplanInclude
            }
            dummyProducts{
                ...fragmentDummyProducts
            }
        }
        subtotal
        shippingAmount
        tax
        total
        yourSavings
        couponData {
            ...fragmentCouponData
        }
        notification
    }
`;

export const FRAGMENT_CUSTOMER = gql`
    fragment fragmentCustomer on CustomerType {
        id
        firstname
        lastname
        email
        created_at
        updated_at
        links {
            addressBookLink
            editLink
            preferencesLink
        }
    }
`;

export const FRAGMENT_REGION_DATA = gql`
  fragment fragmentRegionData on Region {
    id
    name
    code
  }
`;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_bN\\+9x{background:var(--cp-white);height:auto!important;padding:0 16px;position:sticky;top:0;z-index:99}@media only screen and (min-width:768px){.wrapper_bN\\+9x{padding:0 24px}}@media only screen and (min-width:1264px){.wrapper_bN\\+9x{padding:0 60px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_bN+9x"
};
module.exports = ___CSS_LOADER_EXPORT___;

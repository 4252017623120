
import widgetSrc from '@/assets/widget-91x73.svg';

export default {
  name: 'FooterNavigationWidget',
  data() {
    return {
      widgetSrc,
    };
  },

  methods: {
    handleClick() {
      if (this.$route.path === '/reviews') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
};

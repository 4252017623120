// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".guardian_Bt8tO{grid-column:1/-1;margin:-8px 0}.choosePlan_DbeR2{background:var(--cp-gray-8);border-radius:4px;padding:8px 16px}.row_35U0\\+{display:grid;grid-gap:0 16px;align-items:center;gap:0 16px;grid-template-areas:\"icon name\" \"icon button\";grid-template-columns:32px 1fr}.name_DrmZP{color:var(--cp-black)}.name_DrmZP:hover{color:var(--cp-primary_main);-webkit-text-decoration:underline;text-decoration:underline}.icon_Zy1Gl{flex-shrink:0;grid-area:icon;margin:4px 0 -4px}.price_VETEh{margin-top:16px;text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guardian": "guardian_Bt8tO",
	"choosePlan": "choosePlan_DbeR2",
	"row": "row_35U0+",
	"name": "name_DrmZP",
	"icon": "icon_Zy1Gl",
	"price": "price_VETEh"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scrollHorizontal_Xorft{overflow-x:auto;overflow-y:hidden}.scrollHorizontal_Xorft::-webkit-scrollbar{background-color:var(--cp-gray-9);border-radius:.8rem;height:.8rem;width:.8rem}.scrollHorizontal_Xorft::-webkit-scrollbar-thumb{background-color:var(--cp-gray-4);border-radius:1.6rem}.submenuHeader_XfAk\\+{align-items:center;border-bottom:.1rem solid var(--cp-gray-4);display:flex;justify-content:space-between;padding-bottom:1.6rem}@media only screen and (min-width:1264px){.submenuHeader_XfAk\\+{border-bottom:none;height:4rem;justify-content:flex-start}}.submenuTitle_aRknQ{font-size:1.8rem;font-weight:600;line-height:2.6rem}.seeAll_zRurM{color:var(--cp-primary_main);display:inline-block;font-size:1.6rem;font-weight:400;line-height:2.2rem;margin-left:auto}.seeAll_zRurM:hover{-webkit-text-decoration:underline;text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollHorizontal": "scrollHorizontal_Xorft",
	"submenuHeader": "submenuHeader_XfAk+",
	"submenuTitle": "submenuTitle_aRknQ",
	"seeAll": "seeAll_zRurM"
};
module.exports = ___CSS_LOADER_EXPORT___;

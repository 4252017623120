// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_YYWrO{align-items:center;display:flex;padding:1.2rem;grid-gap:1.2rem;border-radius:.4rem;color:var(--cp-black)}.link_YYWrO:hover{background-color:var(--cp-secondary-4);-webkit-text-decoration:underline;text-decoration:underline}.icon_F8idR{flex:0 0 auto;height:2.4rem;width:2.4rem}.title_0gOWC{font-size:1.6rem;line-height:2.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_YYWrO",
	"icon": "icon_F8idR",
	"title": "title_0gOWC"
};
module.exports = ___CSS_LOADER_EXPORT___;
